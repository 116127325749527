

























































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { typeProject } from '@/filters/type-project';
import AutoComplete from '@/components/AutoComplete.vue';
import { builderModule } from '@/store/modules/builder';
import ExpandTableMixin from '@/mixins/expand-table';
import { agentModule } from '@/store/modules/agent';
import Notice from '@/components/Notice.vue';

@Component({
    components: { AutoComplete, Notice },
    filters: { typeProject }
})
export default class Builder extends Mixins(ExpandTableMixin) {
    showNotice = false;
    value = 'builderId';
    options = [
        {
            value: 'projectId',
            label: 'Proyecto'
        },
        {
            value: 'builderId',
            label: 'Inmobiliaria'
        }
    ];

    get projects() {
        return builderModule.projects;
    }

    mounted() {
        if (
            agentModule.agent &&
            agentModule.agent.politics &&
            !agentModule.agent.politics.find(
                ({ url }) => url === '/proyectos-listado'
            )
        ) {
            this.showNotice = true;
        }
    }

    searchBuilders(queryString: string, cb: Function) {
        builderModule.index(queryString).then((data) => {
            cb(data);
        });
    }

    searchProjects(queryString: string, cb: Function) {
        builderModule.searchProjects(queryString).then((data) => {
            cb(data);
        });
    }

    selectData(data: any) {
        const key = this.value === 'projectId' ? 'projectId' : 'builderId';
        builderModule.getProjects({ [this.value]: data[key], all:true });
    }

    destroyed() {
        builderModule.setProject([]);
    }
}
