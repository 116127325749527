export function typeProject(value?: number): string | undefined {
    if (!value) return '';

    let type = '';
    switch (value) {
        case 1:
            type = 'Departamento';
            break;
        case 2:
            type = 'Oficina';
            break;
        case 3:
            type = 'Casa';
            break;
        case 4:
            type = 'Lote';
            break;
        default:
            type = '';
            break;
    }
    return type;
}
