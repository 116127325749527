import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ExpandTableMixin extends Vue {
    rows: string[] = [];

    isRowExpanded(id: string): boolean {
        return !!this.rows.find(element => element === id);
    }

    toggleRowExpansion(id: string) {
        const index = this.rows.findIndex(row => row === id);
        if (index >= 0) {
            this.rows.splice(index, 1);
        } else {
            this.rows.push(id);
        }
    }
}
