












































import { Component, Vue } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';

@Component
export default class Notice extends Vue {
    async store() {
        await agentModule.pushPolitics({
            url: this.$route.path
        });
        this.close();
    }

    close() {
        this.$emit('close', true);
    }
}
